<template>
	<v-card flat class="wr_grey_1">
		<StageIntroDialog />

		<v-tabs
			v-model="current_tab"
			background-color="transparent"
			active-class="black--text font-weight-bold"
			slider-color="error"
			centered
		>
			<v-tab href="#timeline" class="title text-truncate">
				<v-icon :left="!$vuetify.rtl" :right="$vuetify.rtl"
					>$vuetify.icons.values.clock</v-icon
				>
				{{ $t("app.timeline") }}
			</v-tab>

			<v-tab href="#calendar" class="title text-truncate">
				<v-icon :left="!$vuetify.rtl" :right="$vuetify.rtl"
					>$vuetify.icons.values.calendar</v-icon
				>
				{{ $t("app.calender") }}
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="current_tab" class="full-height-wr wr_grey_1">
			<!-- Timeline -->
			<v-tab-item value="timeline" class="full-height-wr">
				<v-card flat height="100%" class="wr_grey_1">
					<v-container class="py-0" fill-height fluid>
						<v-row justify="center">
							<v-col align="center">
								<AppModuleProgress
									v-if="latestAvailableStage"
									v-model="latestAvailableStage.value"
									:events="events"
								></AppModuleProgress>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-tab-item>

			<!-- Calendar -->
			<v-tab-item value="calendar" class="full-height-wr wr_grey_1">
				<v-card flat height="100%" class="wr_grey_1">
					<v-card-text class="full-height-wr">
						<CalenderPlan :completePlan="false" />
					</v-card-text>
				</v-card>
			</v-tab-item>
		</v-tabs-items>

		<!-- Show finish source documentation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.confirm_timeline.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-card>
</template>

<script>
import CalenderPlan from "@/components/research/modules/planAndManage/stages/CalenderPlan";
import { accessManagementMixin } from "@/mixins/accessManagementMixin";
import AppModuleProgress from "@/components/ui/AppModuleProgress.vue";
import { mapFields } from "vuex-map-fields";
import moment from "moment";
import { mapState } from "vuex";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";

export default {
	name: "ResearchPlanning",

	mixins: [accessManagementMixin],

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		CalenderPlan,
		AppModuleProgress,
		StageFinishDialog,
		StageIntroDialog,
	},

	computed: {
		...mapFields("planAndManage", {
			current_tab: "current_tab",
		}),

		...mapState({
			current_module: (state) => state.research.current_module,
		}),

		events: function() {
			return this.$store.getters["planAndManage/getModuleStagePlan"](
				"M" + this.current_module,
			);
		},
	},

	created() {
		this.getUserPlan();

		this.$eventBus.$on(
			`${this.current_module}-confirm-module-plan`,
			(payload) => {
				this.dialog = true;
			},
		);
	},

	methods: {
		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */

		this.$eventBus.$off(`${this.current_module}-confirm-module-plan`);
	},
};
</script>
